<template lang="html">
  <div id="about-us-page">

    <section class="banner-section">
      <img class="w-100" src="public/images/pages/about-us/banner-1.jpg">
    </section>

    <section class="container about-us-section">
      <div class="row">
        <div class="col-12 col-text">
          <h1 class="mb-3 text-center title-s-1">Sobre nosotros</h1>

          <p>
            Grupo Orión de Cozumel es una inmobiliaria y desarrolladora de vivienda con mas de 20 años de experiencia en el mercado.
          </p>
          <p class="mt-3">
            Nuestra experiencia se basa en la compraventa y desarrollo de propiedades habitacionales, vacacionales y comerciales, así como en administración de condominios y de rentas.
          </p>
          <h5 class="text-center f-w-900 mt-3">Grupo Orion de Cozumel </h5>
        </div>

        <!-- <div class="col-lg-4 pl-lg-4 mt-3 mt-lg-0 col-image">
          <img src="public/images/pages/about-us/image.jpg">
        </div> -->
      </div>
    </section>

  </div>
</template>

<script>
export default {
}
</script>
