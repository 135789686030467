<template lang="html">
  <div id="properties-page">

    <section class="container oversized-container results-section">
      <div class="row">
        <div class="col-12">
          <div class="mb-3 row row-filters">
            <div class="col-lg-6 col-title-t1">
              <h2 class="title-s-1">Propiedades disponibles</h2>
            </div>

            <div class="col-lg-6 col-options">
              <a class="btn btn-s-1 btn-outline brown" href="https://gocozumel.com.mx/#/propiedades">PROPIEDADES</a>
              <a class="btn btn-s-1 btn-outline brown" href="https://gocozumel.com.mx/#/lotes">LOTES</a>
              <a class="btn btn-s-1 btn-outline brown" href="https://gocozumel.com.mx/#/comerciales">COMERCIAL</a>
            </div>
          </div>
        </div>

        <div class="col-12 col-results">
          <div class="row row-properties">
            <div class="col-md-6 col-lg-4 col-property-sample-1" v-for="(p, phInx) in properties" :key="'phInx-'+phInx">
              <router-link class="box-property" :to="'/propiedades/'+p.id">
                <div class="placed-backg box-image" v-bind:style="{ backgroundImage: 'url(' + p.imageUrl +')' }">
                  <img src="public/images/pages/properties/property.png">
                </div>

                <div class="box-info">
                  <h6 class="name">{{p.name}}</h6>

                  <h5 class="price">{{p.price}} {{p.currency}}</h5>
                  <h5 class="type"><span>{{p.type_operation_name}}</span></h5>

                  <div class="box-extra-info">
                      <div class="col col-info">
                            <i class="icon total"></i> <span class="txt"><small>Terreno</small><br />{{p.meters_ground}} MTS²</span>
                      </div>

                      <div class="col col-info">
                          <i class="icon built"></i> <span class="txt"><small>Construido</small><br />{{p.meters_building}} MTS²</span>
                      </div>

                      <div class="col col-info">
                          <i class="icon garden"></i> <span class="txt"><small>Jardín</small><br />{{p.meters_garden}} MTS²</span>
                      </div>

                      <!-- <div class="col-lg-4 d-none d-xl-inline-flex"></div> -->

                      <div class="col col-info" v-if="p.meters_ground_sqft">
                            <i class="icon total"></i> <span class="txt">{{p.meters_ground_sqft}} SQFT</span>
                      </div>

                      <div class="col col-info" v-if="p.meters_building_sqft">
                          <i class="icon built"></i> <span class="txt">{{p.meters_building_sqft}} SQFT</span>
                      </div>

                      <div class="col col-info" v-if="p.meters_garden_sqft">
                          <i class="icon garden"></i> <span class="txt">{{p.meters_garden_sqft}} SQFT</span>
                      </div>
                  </div>
                </div>

                <div class="btn-more">EXPLORAR</div>
              </router-link>
            </div>

          </div>
        </div>
      </div>
    </section>

  </div>
</template>

<script>
export default {
  data(){
    return{
      properties: []

    }
  },
  methods:{
      getData(){
        axios.get(tools.url("/api/properties")).then((response)=>{
            this.properties = response.data;
          }).catch((error)=>{
          });
      },

  },
  mounted(){
      this.getData();

  }
}
</script>
