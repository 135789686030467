<template lang="html">
  <div id="home-page">

    <section class="container-fluid banner-section">
      <swiper class="swiper-desktop" :options="bannersOption">
        <swiper-slide v-for="(banner, index) in banners.pc" :key="index">
          <a class="d-block placed-backg" v-bind:style="{ backgroundImage: 'url('+banner.imagen+')' }" :href="banner.url">
            <img src="public/images/pages/home/banner.png">
          </a>
        </swiper-slide>

        <div class="swiper-button-prev" slot="button-prev"></div>
        <div class="swiper-button-next" slot="button-next"></div>
      </swiper>

      <swiper class="swiper-mobile" :options="bannersOption">
        <swiper-slide v-for="(banner, index) in banners.movil" :key="index">
          <a class="d-block placed-backg" v-bind:style="{ backgroundImage: 'url('+banner.imagen+')' }" :href="banner.url">
            <img src="public/images/pages/home/banner-m.png">
          </a>
        </swiper-slide>

        <div class="swiper-button-prev" slot="button-prev"></div>
        <div class="swiper-button-next" slot="button-next"></div>
      </swiper>
    </section>

    <section class="properties-section">
      <div class="container oversized-container">
        <div class="mb-3 row">
          <div class="col-lg-6 col-title-t1">
            <h2 class="title">{{filtertype}} disponibles</h2>
            <router-link class="btn-all" v-if="filtertype == 'Propiedad'" to="/propiedades">VER TODAS</router-link>
            <router-link class="btn-all" v-if="filtertype == 'Lotes'" to="/lotes">VER TODAS</router-link>
            <router-link class="btn-all" v-if="filtertype == 'Comercial'" to="/comerciales">VER TODAS</router-link>
          </div>

          <div class="col-lg-6 col-options">
            <a class="btn btn-s-1 btn-outline brown" @click="filtertype = 'Propiedad'">PROPIEDADES</a>
            <a class="btn btn-s-1 btn-outline brown" @click="filtertype = 'Lotes'">LOTES</a>
            <a class="btn btn-s-1 btn-outline brown" @click="filtertype = 'Comercial'">COMERCIAL</a>
          </div>
        </div>

        <div class="row">
          <div class="col-12 col-properties">
            
            <swiper class="swiper" :options="propertiesOptions">
              <!-- Propiedad -->
              <swiper-slide v-if="filtertype == 'Propiedad'" v-for="(p, phInx) in properties" :key="'phInx-'+phInx">
                <!-- <div class="col-md-6 col-lg-4 col-property-sample-1"> -->
                <div class="col-12 px-0 col-property-sample-1">
                  <router-link class="box-property" :to="'/propiedades/'+p.id">
                    <div class="placed-backg box-image" v-bind:style="{ backgroundImage: 'url('+ p.imageUrl +')' }">
                      <img src="public/images/pages/properties/property.png">
                    </div>

                    <div class="box-info">
                      <h6 class="name">{{p.name}}</h6>

                    <h5 class="price">{{p.price}} {{p.currency}}</h5>
                    <h5 class="price">{{p.type_operation_name}}</h5>

                      <div class="box-extra-info">

                        <div class="col col-info">
                            <i class="icon total"></i> <span class="txt"><small>Terreno</small><br />{{p.meters_ground}} MTS²</span>
                        </div>

                        <div class="col col-info">
                          <i class="icon built"></i> <span class="txt"><small>Construido</small><br />{{p.meters_building}} MTS²</span>
                        </div>

                        <div class="col col-info">
                          <i class="icon garden"></i> <span class="txt"><small>Jardín</small><br />{{p.meters_garden}} MTS²</span>
                        </div>


                        <!-- <div class="col-lg-4 d-none d-xl-inline-flex"></div> -->

                        <div class="col col-info" v-if="p.meters_ground_sqft">
                            <i class="icon total"></i> <span class="txt">{{p.meters_ground_sqft}} SQFT</span>
                        </div>

                        <div class="col col-info" v-if="p.meters_building_sqft">
                          <i class="icon built"></i> <span class="txt">{{p.meters_building_sqft}} SQFT</span>
                        </div>

                        <div class="col col-info" v-if="p.meters_garden_sqft">
                          <i class="icon garden"></i> <span class="txt">{{p.meters_garden_sqft}} SQFT</span>
                        </div>

                      </div>
                    </div>

                    <div class="btn-more">EXPLORAR</div>
                  </router-link>
                </div>
              </swiper-slide>
              <!-- Lotes -->
              <swiper-slide v-if="filtertype == 'Lotes'" v-for="(p, phInx) in grounds" :key="'phInx-'+phInx">
                <!-- <div class="col-md-6 col-lg-4 col-property-sample-1"> -->
                <div class="col-12 px-0 col-property-sample-1">
                  <router-link class="box-property" :to="'/lotes/'+p.id">
                    <div class="placed-backg box-image" v-bind:style="{ backgroundImage: 'url('+ p.imageUrl +')' }">
                      <img src="public/images/pages/properties/property.png">
                    </div>

                    <div class="box-info">
                      <h6 class="name">{{p.name}}</h6>

                    <h5 class="price">{{p.price}} {{p.currency}}</h5>
                    <h5 class="price">{{p.type_operation_name}}</h5>

                      <div class="box-extra-info">

                        

                        <div class="col col-info">
                          <i class="icon width"></i> <span class="txt"><small>Frente</small><br />{{p.meters_front}} MTS²</span>
                        </div>

                        <div class="col col-info">
                          <i class="icon height"></i> <span class="txt"><small>Fondo</small><br />{{p.meters_back}} MTS²</span>
                        </div>

                        <div class="col col-info">
                            <i class="icon total"></i> <span class="txt"><small>Terreno</small><br />{{p.meters_ground}} MTS²</span>
                        </div>


                        <!-- <div class="col-lg-4 d-none d-xl-inline-flex"></div> -->

                        

                        <div class="col col-info" v-if="p.meters_front_sqft">
                          <i class="icon built"></i> <span class="txt">{{p.meters_front_sqft}} SQFT</span>
                        </div>

                        <div class="col col-info" v-if="p.meters_back_sqft">
                          <i class="icon garden"></i> <span class="txt">{{p.meters_back_sqft}} SQFT</span>
                        </div>

                        <div class="col col-info" v-if="p.meters_ground_sqft">
                            <i class="icon total"></i> <span class="txt">{{p.meters_ground_sqft}} SQFT</span>
                        </div>

                        
                      </div>
                    </div>

                    <div class="btn-more">EXPLORAR</div>
                  </router-link>
                </div>
              </swiper-slide>
              <!-- Comercial -->
              <swiper-slide v-if="filtertype == 'Comercial'" v-for="(p, phInx) in commercials" :key="'phInx-'+phInx">
                <!-- <div class="col-md-6 col-lg-4 col-property-sample-1"> -->
                <div class="col-12 px-0 col-property-sample-1">
                  <router-link class="box-property" :to="'/propiedades/'+p.id">
                    <div class="placed-backg box-image" v-bind:style="{ backgroundImage: 'url('+ p.imageUrl +')' }">
                      <img src="public/images/pages/properties/property.png">
                    </div>

                    <div class="box-info">
                      <h6 class="name">{{p.name}}</h6>

                    <h5 class="price">{{p.price}} {{p.currency}}</h5>
                    <h5 class="price">{{p.type_operation_name}}</h5>

                      <div class="box-extra-info">

                        <div class="col col-info">
                            <i class="icon total"></i> <span class="txt"><small>Terreno</small><br />{{p.meters_ground}} MTS²</span>
                        </div>

                        <div class="col col-info">
                          <i class="icon built"></i> <span class="txt"><small>Construido</small><br />{{p.meters_building}} MTS²</span>
                        </div>

                        <div class="col col-info">
                          <i class="icon garden"></i> <span class="txt"><small>Jardín</small><br />{{p.meters_garden}} MTS²</span>
                        </div>


                        <!-- <div class="col-lg-4 d-none d-xl-inline-flex"></div> -->

                        <div class="col col-info" v-if="p.meters_ground_sqft">
                            <i class="icon total"></i> <span class="txt">{{p.meters_ground_sqft}} SQFT</span>
                        </div>

                        <div class="col col-info" v-if="p.meters_building_sqft">
                          <i class="icon built"></i> <span class="txt">{{p.meters_building_sqft}} SQFT</span>
                        </div>

                        <div class="col col-info" v-if="p.meters_garden_sqft">
                          <i class="icon garden"></i> <span class="txt">{{p.meters_garden_sqft}} SQFT</span>
                        </div>

                      </div>
                    </div>

                    <div class="btn-more">EXPLORAR</div>
                  </router-link>
                </div>
              </swiper-slide>

              <div class="swiper-pagination" slot="pagination"></div>
            </swiper>
            

             

          </div>
        </div>
      </div>
    </section>

    <!-- <section class="blog-section">
      <div class="container oversized-container">
        <div class="mb-3 row">
          <div class="col-lg-6 col-title-t1">
            <h2 class="title">Comunicados</h2>
            <router-link class="btn-all" to="/isla-de-cozumel">VER TODAS</router-link>
          </div>
        </div>

        <div class="row">
          <div class="col-12 col-md-6 col-lg-4 col-article article-sample-1 lg" v-for="(article, ahInx) in blogs" :key="'ahInx-'+ahInx">
            <router-link class="t-250 placed-backg article-link" v-bind:style="{ backgroundImage: 'url('+article.imageUrl+')' }" :to="'/blog/'+article.id">
              <img class="empty" src="public/images/pages/blog/article-lg.png">

              <div class="inside">
                <div class="shadow"></div>
                <div class="box-txt">
                  <h6 class="category">BLOG</h6>
                  <h5 class="title">{{article.title}}</h5>

                  <h6 class="extra">POR GRUPO ORIÓN</h6>
                  <h6 class="extra">{{article.created}}</h6>
                </div>
              </div>
            </router-link>
          </div>
        </div>
      </div>
    </section> -->

  </div>
</template>

<script>
export default {
  data(){
    return{
      banners: [
        /* { device: 'pc', imageUrl: 'public/images/pages/home/banner-1.jpg' },
        { device: 'pc', imageUrl: 'public/images/pages/home/banner-2.jpg' },
        { device: 'pc', imageUrl: 'public/images/pages/home/banner-3.jpg' },
        { device: 'movil', imageUrl: 'public/images/pages/home/banner-1-m.jpg' },
        { device: 'movil', imageUrl: 'public/images/pages/home/banner-2-m.jpg' },
        { device: 'movil', imageUrl: 'public/images/pages/home/banner-3-m.jpg' }, */
      ],

      blogs: [
        /* { id: 1, imageUrl: 'public/images/pages/blog/article-1.jpg', title: 'Conoce las 10 zonas residenciales mas nuevas de cozumel.' },
        { id: 1, imageUrl: 'public/images/pages/blog/article-2.jpg', title: 'Explora las reservas naturales de Cozumel.' },
        { id: 1, imageUrl: 'public/images/pages/blog/article-3.jpg', title: 'Título de ejemplo de noticia blog ejemplo de blog grupo de orion.' }, */
      ],
      properties:[],
      grounds:[],
      commercials:[],
      /* == Carousel options == */
      bannersOption: {
        loop: true,
        speed:800,
        effect: 'fade',

        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        },

        autoplay: {
          delay: 3500,
          disableOnInteraction: false
        },
      },

      propertiesOptions: {
        slidesPerView: 3,

        spaceBetween: 30,

        pagination: {
          el: '.swiper-pagination',
          clickable: true,
        },

        breakpoints: {
          992: {
            slidesPerView: 3,
          },
          768: {
            slidesPerView: 2,
          },
          1: {
            slidesPerView: 1,
          },
        }
      },
      filtertype:'Propiedad'
      /* === */
    }
  },
  methods:{
      getBanners(){
        axios.get(tools.url("/api/banners")).then((response)=>{
            this.banners = response.data;
          }).catch((error)=>{
          });
      },
      getBlogs(){
        axios.get(tools.url("/api/blogsOutstanding")).then((response)=>{
            this.blogs = response.data;
          }).catch((error)=>{
          });
      },
      getProperties(){
        axios.get(tools.url("/api/propertiesOutstanding")).then((response)=>{
            this.properties = response.data.properties;
            this.grounds = response.data.grounds;
            this.commercials = response.data.commercials;
          }).catch((error)=>{
          });
      },
  },
  mounted(){
      this.getBanners();
      this.getBlogs();
      this.getProperties();
  }
}
</script>
