<template lang="html">
  <header id="header">
    <div class="header-content">
      <b-navbar toggleable="lg" type="light" variant="light">
        <div class="container oversized-container">
          <b-navbar-brand to="/">
            <img src="public/images/logo.svg" alt="Cozumel">
          </b-navbar-brand>

          <b-navbar-toggle target="nav-collapse"><i class="far fa-bars"></i></b-navbar-toggle>

          <b-collapse id="nav-collapse" is-nav>
            <b-navbar-nav class="ml-auto">
              <b-nav-item class="simple-item" to="/propiedades">Propiedades</b-nav-item>
              <b-nav-item class="simple-item" to="/lotes">Lotes</b-nav-item>
              <b-nav-item class="simple-item" to="/proyectos">Proyectos</b-nav-item>
              <b-nav-item class="simple-item" to="/empresa">Empresa</b-nav-item>
              <!-- <b-nav-item class="simple-item" to="/isla-de-cozumel">Isla de Cozumel</b-nav-item> -->

              <li class="nav-item nav-sep">
                <div class="nav-link"><i></i></div>
              </li>

              <li class="nav-item nav-icons">
                <div class="nav-link">
                  <!-- <a><i class="fal fa-search" @click="$refs['modal-search'].show();"></i></a> -->
                  <router-link to="/contacto"><i class="fal fa-envelope"></i></router-link>
                  <a href="#"><i class="fab fa-facebook-f"></i></a>
                  <a href="#"><i class="fab fa-instagram"></i></a>
                </div>
              </li>
            </b-navbar-nav>
          </b-collapse>
        </div>
      </b-navbar>
    </div>

    <b-modal ref="modal-search" modal-class="modal-search-e" size="lg" title="" @hidden="form.keywords = null" hide-footer no-close-on-esc no-close-on-backdrop>
      <!-- NOTA: form.keywords será null siempre al cerrar el modal, esto por usar el evento @hidden -->

      <b-form @submit="onSubmitSearch">
        <b-form-group>
          <b-form-input
            v-model="form.keywords"
            type="text"
            placeholder="Buscar"
            size="lg"
            required
          ></b-form-input>
        </b-form-group>

        <b-button type="submit" class="btn-search"><i class="far fa-search"></i></b-button>
      </b-form>
    </b-modal>
  </header>
</template>

<script>
export default {
  data(){
    return{
      form: {
        keywords: null,
      }
    }
  },

  methods: {
    onSubmitSearch(event) {
      event.preventDefault();
      this.$router.push({path: '/busqueda', query: {keywords:this.form.keywords}});
      this.$refs['modal-search'].hide();
    },
  },
}
</script>
