<template lang="html">
  <div id="projects-page">

    <section class="container oversized-container results-section">
      <div class="row">
        <div class="col-12">
          <h1 class="title-s-1 mb-2">Proyectos</h1>
        </div>

        <div class="col-12 col-results">
          <div class="row">
            <div class="col-md-6 col-xl-4 col-project-sample-1" v-for="(p, pInx) in projects" :key="'pInx-'+pInx">
              <router-link class="box-project" :to="'/proyectos/'+p.id">
                <img class="fake" src="public/images/pages/projects/project.png" v-bind:style="{ backgroundImage: 'url('+p.imageUrl+')' }">

                <div class="box-info">
                  <div class="box-name">
                    {{ p.name }}
                  </div>
                </div>
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </section>

  </div>
</template>

<script>
export default {
  data(){
    return{
      projects: [
        /* { image: 'public/images/pages/projects/project-1.jpg', name: 'Nombre del proyecto' },
        { image: 'public/images/pages/projects/project-2.jpg', name: 'Nombre del proyecto' },
        { image: 'public/images/pages/projects/project-3.jpg', name: 'Nombre del proyecto' },
        { image: 'public/images/pages/projects/project-1.jpg', name: 'Nombre del proyecto' },
        { image: 'public/images/pages/projects/project-2.jpg', name: 'Nombre del proyecto' },
        { image: 'public/images/pages/projects/project-3.jpg', name: 'Nombre del proyecto' },
        { image: 'public/images/pages/projects/project-1.jpg', name: 'Nombre del proyecto' },
        { image: 'public/images/pages/projects/project-2.jpg', name: 'Nombre del proyecto' },
        { image: 'public/images/pages/projects/project-3.jpg', name: 'Nombre del proyecto' }, */
      ]
    }
  },
  methods:{
      getProjects(){
        axios.get(tools.url("/api/projects")).then((response)=>{
            this.projects = response.data;

          }).catch((error)=>{
              this.$parent.handleErrors(error);
          });
      },
  },
  mounted(){
      this.getProjects();
  }
}
</script>
