<template lang="html">
  <div id="project-page">

    <section class="banner-section">
      <swiper class="swiper swiper-s1" :options="galleryOptions">
        <swiper-slide>
          <div class="placed-backg box" v-bind:style="{ backgroundImage: 'url('+project.imageUrl+')' }">
            <img src="public/images/pages/projects/image.png">
          </div>
        </swiper-slide>

        <div class="swiper-button-prev" slot="button-prev"></div>
        <div class="swiper-button-next" slot="button-next"></div>
      </swiper>
    </section>

    <section class="container about-us-section">
      <div class="row">
        <div class="col-12 col-text">
          <h1 class="mb-2 text-center title-s-1 sm">{{project.name}}</h1>
          <p>{{project.short_description}}</p>
          <div v-html="project.description">

          </div>
        </div>
      </div>
    </section>

  </div>
</template>

<script>
export default {
  data(){
    return{
      id:null,
      // == Carousel options ==
      galleryOptions: {
        loop: true,
        speed: 750,

        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        },

        autoplay: {
          delay: 3500,
          disableOnInteraction: false
        },
      },
      project:{}
      // == ==
    }
  },
  methods:{
      getProject(){
        axios.get(tools.url("/api/projects/"+this.id)).then((response)=>{
            this.project = response.data;

          }).catch((error)=>{
              this.$parent.handleErrors(error);
          });
      },
  },
  mounted(){
    if(this.$route.params.id){
				this.id=this.$route.params.id;
        this.getProject();
    }
  }
}
</script>
