<template lang="html">
  <div id="property-page">

    <!-- <section class="banner-section">
      <img src="public/images/pages/properties/lote-lg-1.jpg">
    </section> -->

    <section class="container oversized-container property-section">
      <div class="row">
        <div class="col-lg-7 col-info">
          <h3 class="prop-name">{{row.name}}</h3>

          <div class="box-extra-info">
            <div class="col col-price">
              <h4>${{row.price}} {{row.currency}}</h4>
              <h4 class="type">{{row.type_operation_name}}</h4>
            </div>

            <div class="col col-info lote">
              <div class="box width">
                <div class="ins">
                  <strong>FRENTE:</strong> {{row.meters_front}} MTS²
                </div>
              </div>
              <div class="box height">
                <div class="ins">
                  <strong>FONDO:</strong> {{row.meters_back}} MTS²
                </div>
              </div>
              <div class="box total">
                <div class="ins">
                  <strong>TOTAL: </strong> {{row.meters_ground}} MTS²
                </div>
              </div>
            </div>
          </div>

          <div class="box-gallery">
            <swiper class="swiper swiper-s1" :options="galleryOptions">
              <swiper-slide v-for="(img,indx) in row.images" :key="indx">
                <img :src="img.url">
              </swiper-slide>

              <div class="swiper-button-prev" slot="button-prev"></div>
              <div class="swiper-button-next" slot="button-next"></div>
            </swiper>
          </div>

          <div class="box-content">
            <h5 class="mb-2 title">Descripción</h5>

            <div class="box" v-html="row.description">

            </div>
          </div>
        </div>

        <div class="col-lg-5 pl-xl-5 mt-3 mt-lg-0 col-map">
          <div class="box-map">
            <!-- <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3738.012080189026!2d-86.97640168469663!3d20.46469701216931!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x46c110d3dc880f62!2zMjDCsDI3JzUyLjkiTiA4NsKwNTgnMjcuMiJX!5e0!3m2!1ses-419!2sus!4v1664978867353!5m2!1ses-419!2sus" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
           -->
           <GmapMap
							:center="{lat:row.lat, lng:row.lng}"
							:zoom="16"
              map-type-id="terrain">
              <GmapMarker
                :position="{lat:row.lat, lng:row.lng}"
                :clickable="false"
                :draggable="false"
                v-if="row.lat != null && row.lng != null"/>
						</GmapMap>
         </div>

          <!-- <div class="box-contact">
            <div class="col col-photo">
              <div class="placed-backg photo" v-bind:style="{ backgroundImage: 'url(public/images/pages/properties/photo.jpg)' }"></div>
            </div>

            <div class="col col-info">
              <h5 class="name">Jesús Torres Amezcua</h5>
              <h6 class="position">Asistente Ejecutivo en Orión</h6>

              <div class="box-sep">
                <hr class="sep" />
              </div>

              <p>
                Número: (33) 3333-3333
              </p>
              <p>
                Correo: email@example.com
              </p>
            </div>

            <div class="col-12 mt-2 col-button">
              <router-link class="btn btn-s-2 blue" to="/contacto">ENVIAR MENSAJE</router-link>
            </div>
          </div> -->
        </div>
      </div>
    </section>

  </div>
</template>

<script>
export default {
  data(){
    return{

      // == Carousel options ==
      galleryOptions: {
        // loop: true,
        speed: 750,

        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        },

        autoplay: {
          delay: 3500,
          disableOnInteraction: false
        },
      },
      // == ==
      id:null,
      row:{}
    }
  },
  methods:{
      getData(){
        axios.get(tools.url("/api/properties/"+this.id)).then((response)=>{
            this.row = response.data;
          }).catch((error)=>{
          });
      },

  },
  mounted(){
    if(this.$route.params.id){
				this.id=this.$route.params.id;
        this.getData();
    }
  }
}
</script>
